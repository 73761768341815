/*eslint-disable*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import LogoNew from '../../assets/img/logo.gif';
import LogoGlow from '../../assets/img/logo.png';
import xLogo from '../../assets/img/x.png';
import telegramLogo from '../../assets/img/telegram.png';
// core components
import AdminNavbarLinks from '../../components/Navbars/AdminNavbarLinks.js';
import RTLNavbarLinks from '../../components/Navbars/RTLNavbarLinks.js';

import styles from '../../assets/jss/material-dashboard-react/components/sidebarStyle.js';

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  let location = useLocation();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return location.pathname === routeName;
  }
  const { color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = ' ';
        var listItemClasses;
        // if (prop.path === "/upgrade-to-pro") {
        //   activePro = classes.activePro + " ";
        //   listItemClasses = classNames({
        //     [" " + classes[color]]: true,
        //   });
        // } else {
        listItemClasses = classNames({
          [' ' + classes[color]]: activeRoute(prop.layout + prop.path),
        });
        // }
        const whiteFontClasses = classNames({
          [' ' + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        return (
          <NavLink to={prop.layout + prop.path} className={activePro + classes.item} activeClassName="active" key={key}>
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === 'string' ? (
                // <Icon
                //   className={classNames(classes.itemIcon, whiteFontClasses, {
                //     [classes.itemIconRTL]: props.rtlActive,
                //   })}
                // >
                //   {prop.icon}
                // </Icon>
                <img src={prop.icon} className="nav-item-icon" />
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive,
                  })}
                />
              )}
              <ListItemText
                primary={props.rtlActive ? prop.rtlName : prop.name}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive,
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo + ' logo-container'}>
      <a
        href="#"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        // target="_blank"
      >
        {/* <div className={classes.logoImage}></div> */}
        <img src={LogoGlow} alt="logo" className={classes.img + ' img-logo'} />
        {/* Dao Fund */}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">

        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? 'left' : 'right'}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper + ' drawerPaper-custom', {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper + ' sidebarWrapper-custom'}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
             <div className={classes.socialsMobile}> SOCIALS: 
              <a href="https://twitter.com/deploygold"><img src={xLogo} class={classes.socialIcon}/> </a>
              <a href="https://t.me/deploygold" ><img src={telegramLogo} class={classes.socialIcon}/> </a>
            </div>

          </div>

          {image !== undefined ? (
            <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css" className='PrivateHiddenCss-smDown'>
        <Drawer
          anchor={props.rtlActive ? 'right' : 'left'}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper + ' drawerPaper-custom', {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper + ' sidebarWrapper-custom'}>{links}</div>
          <div className={classes.sidebarWrapper + ' sidebarWrapper-custom'}>
            <div className={classes.socials}> SOCIALS: 
              <a href="https://twitter.com/deploygold"><img src={xLogo} class={classes.socialIcon}/> </a>
              <a href="https://t.me/deploygold" ><img src={telegramLogo} class={classes.socialIcon}/> </a>
            </div>
          </div>
          {image !== undefined ? (
            <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
          ) : null}
        </Drawer>

      </Hidden>

    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red']),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
