import { StandardTokenABI } from './ABI/StandardToken/Token.js';
import { TokenLockABI } from './ABI/TokenLock/tokenlock.js';
import { LptokenLockABI } from './ABI/LiquidityLock/lptokenlocker.js';
import { LptokenABI } from './ABI/LiquidityLock/lptoken.js';
import { PresaleManageABI } from './ABI/Presale/PresaleManage.js';
import { PresaleSettingABI } from './ABI/Presale/PresaleSetting.js';
import { PresaleABI } from './ABI/Presale/Presale.js';
// import {PresaleABI} from "./ABI/Presale/Presale_Temp.js";
import { LockForwarderABI } from './ABI/Presale/PresaleLockForwarder.js';
import { manageCreateTokenABI } from './ABI/CreateToken/CreateManage.js';
import { standardTokenCreateABI } from './ABI/CreateToken/StandardToken.js';
import { liquidityTokenCreateABI } from './ABI/CreateToken/LiquidityToken.js';

export const TOKENLOCK_ADDRESS = {
  GOLDXMainnet: '0xE3CB374411c2c271cBBC528308852d1dF4b9cb21',
};

export const LIQUIDITYLOCK_ADDRESS = {
  GOLDXMainnet: '0xE3CB374411c2c271cBBC528308852d1dF4b9cb21', // '0xfffFD04Fc7d6f0F6180A13A6875a0BFd3639990d',
};

export const PRESALE_MANAGE_ADDRESS = {
  GOLDXMainnet: '0x0000000000000000000000000000000000000000',
};

export const CREATE_TOKEN_MANAGE_ADDRESS = {
  GOLDXMainnet: '0x5d9bbA2103086e6dA0f692bd9025828075b2036E',
  Ethereum: '0xD3d29d9276c75496Db5e90af383ec45966223590',
  BSC: '0x5588f33F8B7Ce94b9c3b47429f4BF6611C8FBd2D',
  Pulse: '0xBe415681eEe6afECAbB8e5ECeF21B31f976110be'
};

////////////////////////////////////////////////////////////////////////////////////////////

export const STANDARD_TOKEN_ABI = StandardTokenABI;

export const TOKEN_LOCK_ABI = TokenLockABI;

export const LIQUIDITY_LOCK_ABI = {
  lock: LptokenLockABI,
  token: LptokenABI,
};

export const PRESALE_ABI = {
  manage: PresaleManageABI,
  setting: PresaleSettingABI,
  presale: PresaleABI,
  lockforwarder: LockForwarderABI,
};

export const CREATE_TOKEN_ABI = {
  manage: manageCreateTokenABI,
  standard: standardTokenCreateABI,
  liquidity: liquidityTokenCreateABI,
};

export const GetCoinPriceURL = {
  GOLDXMainnet: 'https://min-api.cryptocompare.com/data/price?fsym=BOBA&tsyms=BTC,USD,EUR',
};

export const CRYPTCOMPARE_API_KEY = '2b0dfed1b47571bdae1735c9b393014f03678d41a249248c24fc0e400b0854f9';
