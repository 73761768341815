//Default
import React from 'react';
import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
import CardAvatar from '../../components/Card/CardAvatar.js';
import CardFooter from '../../components/Card/CardFooter.js';

//Group
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import { makeStyles } from '@material-ui/core/styles';
import FuseGoldImage from '../../assets/img/fusegold.jpg';
import BackgroundVid from '../../assets/img/golddesign.mp4';
import BackgroundImg from '../../assets/img/golddesign.gif';
import LogoGlow from '../../assets/img/logo.png';
import XbridgeLogo from '../../assets/img/xbridgelogo.png';

import {isMobile} from 'react-device-detect';

const styles = {
  tick: {
    marginLeft: '-10px',
    color: "#B89554",
    fontSize: 15
  },
  headerText: {
    fontSize:25,
    textAlign:"center"
  },
  title: {
    color: "#B89554",
    fontWeight: "bold",
    fontSize: 14
  },
  subTitle:{
    fontWeight: "bold",
    fontSize: 14
  },
  copyright:{
     color: "#B89554",
     fontSize:14
  },
  devprogram:{
    fontWeight:"bold",
    textAlign:"center"
  },
  fusegold:{
    marginLeft:"5px",
    marginRight:"5px",
    maxWidth:"100px",
    display:"inline-block"
  },
  backgroundvideo: {
  width: "100vw",
  height: "100vh",
  objectFit: "cover",
  position: "fixed",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: -1,
},
  cardThree: {
    background: "#181818 !important",
    padding: "8px",
    borderRadius: "8px !important",
    color: "#ffffff !important",
    width: "auto !important",
    boxShadow: "none !important",
    opacity: 0.8,
    border: "2px solid #B89554 !important",
    minHeight: 500
  },
  normal: {
    fontSize:14
  },
  footer:{
    textAlign:"center"
  }
};

const useStyles = makeStyles(styles);

function Home() {

   const classes = useStyles();

  return (
    <>
    {
      isMobile ?  <img src={BackgroundImg} className={classes.backgroundvideo} /> : 
      <video className={classes.backgroundvideo} autoPlay loop muted>
        <source src={BackgroundVid} type="video/mp4"/>
      </video>
    }
   
    <GridContainer justifyContent="center">
       <img src={LogoGlow} alt="logo" className={classes.img + ' img-logo'} />
    </GridContainer>
    <GridContainer justifyContent="center">
    <GridItem xs={10} sm={12} md={12}>
      <p class={classes.headerText}>The cutting-edge application built exculsively for the GoldX Chain.</p>
      </GridItem>
    </GridContainer>
    <GridContainer justifyContent="center">
      <GridItem xs={10} sm={4} md={4}>
        <Card className={classes.cardThree}>
      
          <CardBody className={'card-dark-body'}>
          <p class={classes.subTitle}>Our mission at deploy.gold is to establish ourselves as the premier all-in-one platform for projects launching on the Gold X Chain. <br/>
Our standout features include:</p>
          <br/>
          <p class={classes.normal}>
           Our "<b>Token Creation Tool</b>" which empowers anyone to seamlessly deploy and create tokens on the Gold X Chain, as well as other prominent chains such as Ethereum and Pulsechain.
          </p>
           <br/>
          <p class={classes.normal}>
           Our <b>bridge</b> <a href="https://xbridge.gold">(xbridge.gold) </a>for the Gold X Chain from Ethereum and Binance Smart Chain, which allows seamless transactions of any ERC-20 tokens coming in and out of the GOLDX ecosystem. 
          </p>
           <br/>
          <p class={classes.normal}>
           Our “<b>Liquidity Locker</b>” on the Gold X Chain, allowing developers and project owners to be transparent in the safety of investors funds. 
          </p>
          </CardBody>
         
        </Card>
      </GridItem>
         <GridItem xs={10} sm={4} md={4} justifyContent="center" className={styles.cardThree}>
         <Card className={classes.cardThree}>
      
          <CardBody className={'card-dark-body'}>
          <p class={classes.title}>Key advantages: </p>
          <br/>
          <p class={classes.subTitle}>Token Creation Tool: </p>
          <ul>
            <li><span class={classes.tick}> ✓ </span>Swift and user-friendly token generation on Gold X Chain, Ethereum, Pulsechain, and more.</li>
            <li><span class={classes.tick}> ✓ </span> No need for smart contract programming.</li>
            <li><span class={classes.tick}> ✓ </span> Complete ownership of your generated tokens.</li>
            <li><span class={classes.tick}> ✓ </span> Customize token names, symbols, functions, and initial supplies.</li>
            <li><span class={classes.tick}> ✓ </span> Sign and create with your own wallet.</li>
          </ul>
          <br/>
          <p class={classes.subTitle}>Liquidity Locker: </p>
          <ul>
            <li><span class={classes.tick}> ✓ </span> Ensure safety to your investors.</li>
            <li><span class={classes.tick}> ✓ </span> Lock tokens in seconds.</li>
            <li><span class={classes.tick}> ✓ </span> Display your lockups to investors.</li>
          </ul>
          </CardBody>
         
        </Card>
        
        </GridItem>
         <GridItem xs={10} sm={4} md={4} justifyContent="center" >
         <Card  className={classes.cardThree}>
      
          <CardBody className={'card-dark-body'}>
          <img src={XbridgeLogo} alt="logo" className={classes.img + ' img-logo'} />
          <p class={classes.title}>Bridge - <a href="https://xbridge.gold">xbridge.gold </a> </p>
          <ul>
            <li><span class={classes.tick}> ✓ </span> Fast, efficient bridge in and out of the Gold X Chain from Ethereum and Binance Smart Chain.</li>
            <li><span class={classes.tick}> ✓ </span> Bridge any ERC-20 tokens.</li>
            <li><span class={classes.tick}> ✓ </span> Multi choice wallet connection (MetaMask, WalletConnect and more).</li>
          </ul>
          </CardBody>
         
        </Card>
        
        </GridItem>
      </GridContainer>
 <GridContainer justifyContent="center">
 <GridItem xs={10} sm={10} md={10} className={classes.footer}>
    <p>Stay updated with our latest news by following our social media channels. Full support and user guide available in our telegram.</p><br/><br/>
      <span class={classes.devprogram}>Official member of the</span>
            <span><img src={FuseGoldImage} alt="fuse.gold" className={classes.fusegold} /> </span>
            <span class={classes.devprogram}> VIP developers bounty program.</span><br/><br/>
            </GridItem>
  </GridContainer>
  <GridContainer justifyContent="center">
          <p class={classes.copyright}> © Deploy.Gold 2024 </p>
  </GridContainer>
   
    </>
  );
}

export default Home;
