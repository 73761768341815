/*eslint-disable*/
//Default
import React from 'react';
import { useState, useEffect } from 'react';

//Style
import { makeStyles } from '@material-ui/core/styles';
// import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

//Group
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
import CardFooter from '../../components/Card/CardFooter.js';

//Icon
import Accessibility from '@material-ui/icons/Accessibility';
import CardIcon from '../../components/Card/CardIcon.js';
import Warning from '@material-ui/icons/Warning';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SecurityUpdateWarningIcon from '@mui/icons-material/SecurityUpdateWarning';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Checkbox from '@mui/material/Checkbox';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import SendIcon from '@mui/icons-material/Send';
import AddCircleIcon from '@mui/icons-material/AddCircle';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

//Typography
import Danger from '../../components/Typography/Danger.js';
import Success from '../../components/Typography/Success.js';
import Primary from '../../components/Typography/Primary.js';
import Info from '../../components/Typography/Info.js';

//Component
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import AlertDialog from '../../components/AlertDlg/Alert.js';
import ProgressDlg from '../../components/AlertDlg/ProgressDlg.js';
import FeeInfo from '../../components/CreateToken/FeeInfo.js';
import CreateToken from '../../components/CreateToken/CreateToken.js';
import LoadTokens from '../../components/CreateToken/LoadTokens.js';

//Input
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CustomInput from '../../components/CustomInput/CustomInput.js';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

//Constant ABI
import { CREATE_TOKEN_ABI } from '../../Config/config.js';

//Constant Address
import { CREATE_TOKEN_MANAGE_ADDRESS } from '../../Config/config.js';

//Web3
//Web3 Interface
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';
import { Contract, ethers, BigNumber as EthersBigNumber } from 'ethers';
import coinAddressValidator from 'coin-address-validator';
import { hexZeroPad } from '@ethersproject/bytes';
import BigNumber from 'bignumber.js';
import { utils } from 'ethers';

import { getDefaultProvider } from '../../components/WalletConnector.js';
import isValidAddress from '../../components/AddressValidator.js';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

let managecontractAddr = CREATE_TOKEN_MANAGE_ADDRESS.Sepolia;

export default function CreateTokenMain() {
  const { account, library } = useWeb3React();

  //Alert Dlg
  const [dlgshow, setDlgshow] = useState(false);
  const [errorstr, setErrorStr] = useState('Error');

  //Progress Dlg
  const [progressdlg_flag, setProgressDlgFlag] = useState(false);
  const [progress_str, setProgressDlgStr] = useState('Starting');

  const [tokenList, setTokenList] = useState([]);

  const [coin, setCoin] = useState('');

  const [dashboard, setDashboard] = useState(false);

  const [outlinflag_create, setOutlineCreate] = useState('contained');
  const [outlinflag_dashboard, setOutlineDashboard] = useState('outlined');

  const classes = useStyles();

  const [feeInfo, setFeeInfo] = useState('000');

  const ErrorDlgShow = (flag, alertstr) => {
    setErrorStr(alertstr);
    setDlgshow(flag);
  };

  const ProgressDlgShow = (flag, alertstr) => {
    setProgressDlgStr(alertstr);
    setProgressDlgFlag(flag);
  };

  const onClose = () => {
    ErrorDlgShow(false, '');
  };

  useEffect(() => {
    async function getFee() {
      await getFeeInfo();
    }

    if (!library) {
      return;
    }

    if (!library._network) {
      return;
    }

    if (library._network.chainId === 42355) {
      setCoin('GOLDX');
      managecontractAddr = CREATE_TOKEN_MANAGE_ADDRESS.GOLDXMainnet;
    }
   if(library._network.chainId === 1) {
      setCoin('ETH');
      managecontractAddr = CREATE_TOKEN_MANAGE_ADDRESS.Ethereum;
    }
    if(library._network.chainId === 56) {
      setCoin('BNB');
      managecontractAddr = CREATE_TOKEN_MANAGE_ADDRESS.BSC;
    }
    if(library._network.chainId === 369) {
      setCoin('PLS');
      managecontractAddr = CREATE_TOKEN_MANAGE_ADDRESS.Pulse;
    }

    console.log(managecontractAddr);
    if (account != undefined) {
      getFee();
    }
    // getPrice();
  }, [account, library]);

  useEffect(() => {
    async function getFee() {
      await getFeeInfo();
    }

    if (!library) {
      return;
    }

    if (!library._network) {
      return;
    }

    if (library._network.chainId === 42355) {
      setCoin('GOLDX');
      managecontractAddr = CREATE_TOKEN_MANAGE_ADDRESS.GOLDXMainnet;
    }
   if(library._network.chainId === 1) {
      setCoin('ETH');
      managecontractAddr = CREATE_TOKEN_MANAGE_ADDRESS.Ethereum;
    }
    if(library._network.chainId === 56) {
      setCoin('BNB');
      managecontractAddr = CREATE_TOKEN_MANAGE_ADDRESS.BSC;
    }
    if(library._network.chainId === 369) {
      setCoin('PLS');
      managecontractAddr = CREATE_TOKEN_MANAGE_ADDRESS.Pulse;
    }
    if (!account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
    } else {
      getFee();
    }
  }, []);

  const connectContract = async (tokenabi, tokenaddr) => {
    let provider = await getDefaultProvider();
    let tempcontract;

    if (!library) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      return;
    }

    if (!library._network) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      return;
    }

    const isEthAddress = coinAddressValidator.validate(tokenaddr, 'eth', 'prod');

    if (!isEthAddress) {
      // setErrLabel("Token address is invalied");
      ErrorDlgShow(true, 'Address is invalid');
      return null;
    }

    try {
      tempcontract = new Contract(tokenaddr, tokenabi, provider);
    } catch (error) {
       console.log(error)
       ErrorDlgShow(true, 'Contract connect error');
      // setErrLabel("Contract connect error");
      return null;
    }

    return tempcontract;
  };

  const CreateManageContract = async () => {
    let manageContract;

    if (!library || !account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      return null;
    }

    manageContract = await connectContract(CREATE_TOKEN_ABI.manage, managecontractAddr);

    if (!manageContract) {
      ErrorDlgShow(true, 'Manage Contract connect error');
      ProgressDlgShow(false, '');
      return null;
    }

    let signer = await library.getSigner();

    if (signer) {
      try {
        manageContract = await manageContract.connect(signer);
        return manageContract;
      } catch (error) {
        ErrorDlgShow(true, 'Manage Contract signer connect error');
        ProgressDlgShow(false, '');
      }
    } else {
      return null;
    }
  };

  const createStandardToken = async (standard_info) => {
    getFeeInfo();
    let manage_contract;

    if (!account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      ProgressDlgShow(false, '');
      return;
    }

    ProgressDlgShow(true, 'Creating');

    manage_contract = await CreateManageContract();

    if (!manage_contract) {
      ErrorDlgShow(true, 'Manage contract connect error');
      ProgressDlgShow(false, '');
      return;
    }

    console.log("Fee " + ethers.utils.parseUnits(feeInfo, 18));
    let overrid = {
      value: ethers.utils.parseUnits(feeInfo, 18).toString()
    };
    console.log('----------------------standard_info', standard_info);
    console.log('--------manage contract ', manage_contract);
    //Set Create Fee & Address
    try {
      //       function createStandard(
      //     address creator_,
      //     string memory name_,
      //     string memory symbol_,
      //     uint8 decimals_,
      //     uint256 tokenSupply_,
      //     SharedStructs.status memory _state
      // )
      //       struct status {
      //     uint256 mintflag;
      //     uint256 pauseflag;
      //     uint256 burnflag;
      //     uint256 blacklistflag;
      // }
      if(standard_info.tax_address === ''){
        standard_info.tax_address = standard_info.owner;
      }

      const status = { 
        taxaddress: standard_info.tax_address,
        taxamount: parseInt(standard_info.tax_fee),
        mintflag: parseInt(standard_info.mint),
        pauseflag: parseInt(standard_info.pause),
        burnflag: parseInt(standard_info.burn),
        blacklistflag: parseInt(standard_info.blacklist),
      };

      console.log("FEE ", ethers.utils.parseUnits(feeInfo.toString(), 18));

    let overrid = {
      value: ethers.utils.parseUnits(feeInfo.toString(), 18)
    };

      await manage_contract.createStandard(
        standard_info.owner,
        standard_info.name,
        standard_info.symbol,
        parseInt(standard_info.decimal),
        ethers.utils.parseUnits(standard_info.totalsupply.toString(), standard_info.decimal),
        status,
        overrid
      );

      await manage_contract.on('CreateStandardSuccess', (tokenaddr) => {
        // setProgressFlag(false);
        // getStandardTokenBalance(erctokenaddr);
        // console.log(tokenaddr);
        ErrorDlgShow(true, 'Your New Contract Address\n' + tokenaddr);
        ProgressDlgShow(false, '');
        // getInfo();
      });
    } catch (error) {
      console.log('Creatiing error', error);
      ErrorDlgShow(true, 'Create token Error');
      ProgressDlgShow(false, '');
      return;
    }
  };

  const createLiqudityToken = async (standard_info, deflation_info) => {
    getFeeInfo();
    let manage_contract;
    console.log('----------------------standard_info', standard_info);

    if (!account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      ProgressDlgShow(false, '');
      return;
    }

    ProgressDlgShow(true, 'Creating');

    manage_contract = await CreateManageContract();

    if (!manage_contract) {
      ErrorDlgShow(true, 'Manage contract connect error');
      ProgressDlgShow(false, '');
      return;
    }

    let overrid = {
      value: ethers.utils.parseUnits(feeInfo, 18),
    };

    // console.log("overrid", overrid)
    // console.log("info", standard_info, deflation_info);
    // ProgressDlgShow(false, "");
    // return;
    //Set Create Fee & Address
    console.log('----------------------standard_info', standard_info);

    try {
      await manage_contract.createLiuidity(
        standard_info.owner,
        deflation_info.tax_address,
        standard_info.name,
        standard_info.symbol,
        parseInt(standard_info.decimal),
        ethers.utils.parseUnits(standard_info.totalsupply.toString(), standard_info.decimal.toString()),
        parseInt(deflation_info.flag),
        [
          parseInt(deflation_info.tax_fee),
          parseInt(deflation_info.burn_fee),
          parseInt(deflation_info.hold_fee),
          parseInt(deflation_info.lp_fee),
        ],
        parseInt(standard_info.mint),
        parseInt(standard_info.burn),
        parseInt(standard_info.pause),
        parseInt(standard_info.blacklist),
        overrid
      );

      await manage_contract.on('createLiquditySuccess', (tokenaddr) => {
        // setProgressFlag(false);
        // getStandardTokenBalance(erctokenaddr);
        // console.log(tokenaddr);
        ErrorDlgShow(true, 'Your New Contract Address\n' + tokenaddr);
        ProgressDlgShow(false, '');
        // getInfo();
      });
    } catch (error) {
      console.log('Creatiing error', error);
      ErrorDlgShow(true, 'Create token Error');
      ProgressDlgShow(false, '');
      return;
    }
  };

  const getFeeInfo = async () => {
    let manage_contract;
    console.log('--------------get fee info');
    if (!account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      ProgressDlgShow(false, '');
      return;
    }

    manage_contract = await CreateManageContract();

    if (!manage_contract) {
      ErrorDlgShow(true, 'manage contract connect error');
      ProgressDlgShow(false, '');
      return;
    }

    let fee;

    try {
      console.log('--------------get manage_contract info', manage_contract);

      fee = await manage_contract.fee();
     // console.log('fee', fee);
    } catch (error) {
      console.log(error);
      ErrorDlgShow(true, 'Get Fee Information Error');
      ProgressDlgShow(false, '');
      return;
    }
    console.log('-----------------------fee', ethers.utils.formatUnits(fee, 18));
    setFeeInfo(ethers.utils.formatUnits(fee, 18));

    // console.log("normal fee", feeInfo.curfee);
    if (feeInfo === '000') {
      setFeeInfo(ethers.utils.formatUnits(fee, 18) );
    }
  };

  const createStandardContract = async (addr) => {
    let tokenContract;

    if (!library || !account) {
      return null;
    }

    tokenContract = await connectContract(CREATE_TOKEN_ABI.standard, addr);

    if (!tokenContract) {
      return null;
    }

    let signer = await library.getSigner();

    if (signer) {
      try {
        tokenContract = await tokenContract.connect(signer);
      } catch (error) {
        console.log(error);
        return null;
      }
    } else {
      return null;
    }
    return tokenContract;
  };

  const createLiquidityContract = async (addr) => {
    let tokenContract;

    if (!library || !account) {
      return null;
    }

    tokenContract = await connectContract(CREATE_TOKEN_ABI.liquidity, addr);

    if (!tokenContract) {
      return null;
    }

    let signer = await library.getSigner();

    if (signer) {
      try {
        tokenContract = await tokenContract.connect(signer);
      } catch (error) {
        console.log(error);
        return null;
      }
    } else {
      return null;
    }
    return tokenContract;
  };

  const getTokenInfo = async (address) => {
    // debugger
    let tokencontract;
    if (!account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      ProgressDlgShow(false, '');
      return null;
    }

    tokencontract = await createStandardContract(address);

    if (!tokencontract) {
      ErrorDlgShow(true, 'token contract connect error');
      ProgressDlgShow(false, '');
      return null;
    }

    let name, symbol, supply, balance, decimal, type;
    let state;

    try {
      type = await tokencontract.isstandard();
      name = await tokencontract.name();
      decimal = await tokencontract.decimals();
      symbol = await tokencontract.symbol();
      supply = await tokencontract.totalSupply();
      balance = await tokencontract.balanceOf(account);
      // if(parseInt(type) === 1) {
      state = await tokencontract.state();
      // }
      // type = await tokencontract.isstandard();
      // console.log("info", name, decimal, symbol, supply, balance, state, type);
      return { address, name, symbol, decimal, supply, balance, type, state };
    } catch (error) {
      console.log(error);
      ErrorDlgShow(true, 'Get Token Information Error');
      ProgressDlgShow(false, '');
      return null;
    }
  };

  const getTokenAdresses = async (address) => {
    let manage_contract;

    if (!account) {
      ErrorDlgShow(true, 'Wallet is unconnected');
      ProgressDlgShow(false, '');
      return;
    }

    manage_contract = await CreateManageContract();

    if (!manage_contract) {
      ErrorDlgShow(true, 'manage contract connect error');
      ProgressDlgShow(false, '');
      return;
    }

    let tokenlists;

    try {
      tokenlists = await manage_contract.getCreatedToken(address);
      // console.log("tokens",tokens);
      // console.log(tokenlists.length);
      setTokenList([]);
      let tokens = [];
      for (let i = 0; i < tokenlists.length; i++) {
        let token = await getTokenInfo(tokenlists[i]);
        if (token) {
          tokens.push(token);
        } else {
          return;
        }
      }
      setTokenList(tokens);
      ProgressDlgShow(false, '');
      // console.log(item);
    } catch (error) {
      console.log(error);
      ErrorDlgShow(true, 'Get Token Information Error');
      ProgressDlgShow(false, '');
      return;
    }
  };

  const getTokens = () => {
    ProgressDlgShow(true, 'Loading');
    getTokenAdresses(account);
  };

 /*const changeFeeInfo = (name, flag) => {
    let select_fee, prev_fee;
    if (feeInfo[name] === '000') {
      ErrorDlgShow(true, 'You should know ' + name + ' fee');
      return false;
    }
    select_fee = ethers.utils.parseUnits(feeInfo[name], 18);
    prev_fee = ethers.utils.parseUnits(feeInfo.curfee, 18);

    if (flag) {
      setFeeInfo({
        ...feeInfo,
        curfee: ethers.utils.formatUnits(prev_fee.add(select_fee)),
      });
    } else {
      setFeeInfo({
        ...feeInfo,
        curfee: ethers.utils.formatUnits(prev_fee.sub(select_fee)),
      });
    }

    return true;
  };
  */

  const CreateButtonClick = () => {
    setOutlineCreate('contained');
    setOutlineDashboard('outlined');
    setDashboard(false);
  };

  const DashboardButtonClick = () => {
    setOutlineCreate('outlined');
    setOutlineDashboard('contained');
    setDashboard(true);
    getTokens();
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={2}></GridItem>
        <GridItem xs={12} sm={12} md={6}>
          {/* <Info></Info> */}
        </GridItem>
      </GridContainer>
      <p />
      <Divider textAlign="center"></Divider>
      <div className="create-token-container MuiGrid-root makeStyles-grid-89 MuiGrid-container">
        <div className={"create-token-left MuiGrid-root makeStyles-grid-90 MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 " + (!dashboard ? "MuiGrid-grid-md-8" : "MuiGrid-grid-md-12")}>
          <div className="create-token-lefttop">
            <Stack direction="row" spacing={1}>
              <Button
                color="primary"
                variant={outlinflag_create}
                onClick={(e) => CreateButtonClick()}
                startIcon={
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="7" width="2" height="16" fill="white" />
                    <rect y="9" width="2" height="16" transform="rotate(-90 0 9)" fill="white" />
                  </svg>
                }
                className="btn-load-token"
                >
                Create
              </Button>
              <Button
                color="primary"
                variant={outlinflag_dashboard}
                onClick={(e) => DashboardButtonClick()}
                startIcon={
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.245 11.5372L2.67657 10.0678C1.00006 9.37747 1.03354 6.99146 2.72876 6.34845L14.3505 1.9402C15.9627 1.3287 17.5413 2.90736 16.9298 4.51951L12.5216 16.1413C11.8785 17.8365 9.49254 17.87 8.80221 16.1934L7.33286 12.625C7.12979 12.1318 6.73816 11.7402 6.245 11.5372Z"
                      stroke="white"
                      stroke-width="2"
                      />
                  </svg>
                }
                className="btn-load-token"
                >
                Your Created Tokens
              </Button>
            </Stack>
          </div>
          {!dashboard ? (
          <CreateToken
            createStandardToken={createStandardToken}
            createLiqudityToken={createLiqudityToken}
            user_addr={account}
          />
          ) : (
            <LoadTokens getTokens={getTokens} tokenListInfo={tokenList} />
          )}
        </div>
        {!dashboard && (
          <div className="create-token-right MuiGrid-root makeStyles-grid-90 MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
            <FeeInfo fee={feeInfo} cur_coin={coin} getFeeInfo={getFeeInfo} />
          </div>
        )}
        <AlertDialog open={dlgshow} onClose={onClose} alerttext={errorstr} />
        <ProgressDlg open={progressdlg_flag} alerttext={progress_str} />
      </div>
    </div>
  );
}